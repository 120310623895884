import ConversationRepository from "../repository/ConversationRepository";
import MessageRepository from "../repository/MessageRepository";
import Message from "./Message";

export default class Conversation {
    constructor(object) {
        this.id = object.id;
        this.title = object.title;
        this.date = object.date ?? new Date();
        this.isActive = object.isActive ?? false;
    }

    static clearEmptyConversations() {
        let conversations = ConversationRepository.getConversations();
        conversations = conversations.filter(c => {
            const messages = c.getMessages();
            return messages.length > 0;
        });
        ConversationRepository.saveAll(conversations);
    }

   

    /**
     * 
     * @returns {Conversation}
     */
    static init() {
        //Conversation.clearEmptyConversations();
        const conversation = new Conversation({
            id: ConversationRepository.nextId()
        });
        conversation.setActive();
        return conversation;
    }

    /**
     * 
     * @param {Message} message 
     */
    addMessage(message) {
        message.conversationId ??= this.id;
        return MessageRepository.save(message)
    }

    getMessages() {
        return this.id === null ? [] : MessageRepository.findByConversationId(this.id);
    }

    /**
     * 
     * @returns {Message|null}
     */
    static getLastMessage(role = null) {
        let conversation = ConversationRepository.getActiveConversation();
        let messages  = conversation.getMessages();

        if(role) {
            messages = messages.filter((message) => message.sender === role);
        }

        let lastElement = messages.length > 0 ? messages[messages.length - 1] : undefined;
        return lastElement;
    }

    setActive() {
        this.isActive = true;
        let conversations = ConversationRepository.getConversations();
        conversations = conversations.map(c => {
            if (this.id === c.id) {
                c.isActive = true;
            } else {
                c.isActive = false;
            }
            return c;
        });
        ConversationRepository.saveAll(conversations);
    }

    /**
    *
    * @param {Array} objectList
    * @returns {Conversation[]}
    */
    static fromList(objectList = []) {
        if (objectList.length === 0)
            return objectList;
        return objectList.map(o => new Conversation(o));
    }
}