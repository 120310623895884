import React, { useState, useEffect, useRef } from "react";
import udmey from "../assets/udmey-icon.png";
import demand from "../assets/demand.png";
import price from "../assets/price.png";
import time from "../assets/time.png";
import arrowRight from "../assets/arrow-right.png";
import menu from "../assets/menu.png";
import Message, { MessageSender } from "../model/Message";
import ConversationRepository from "../repository/ConversationRepository";
import ChatApi from "../api/Api";
import Course from "../model/Course";
import Conversation from "../model/Conversation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowUpRightFromSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import "../assets/chat.css";
import "../assets/welcomeWidget.css";
import MessageRepository from "../repository/MessageRepository";
import AuthApi from "../api/AuthApi";

const MESSAGE_COUNT = 6;

const Chat = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  let [conversation, setConversation] = useState(null);
  let [conversations, setConversations] = useState(ConversationRepository.getConversations());
  const messagesEndRef = useRef(null);
  const promptExamples = [
    {
      "id": 1,
      "text": "Find me the top courses for learning Python programming."
    },
    {
      "id": 2,
      "text": "Suggest a beginner's guide to web development."
    },
    {
      "id": 3,
      "text": "Show me advanced courses on data science."
    },
    {
      "id": 4,
      "text": "What are the best resources for mastering machine learning?"
    },
    {
      "id": 5,
      "text": "Recommend courses for improving public speaking skills."
    }
  ];

  useEffect(() => {
    scrollToBottom();
  }, [conversations]);

  if (conversation == null) {
    Conversation.init()
    Conversation.clearEmptyConversations();
    const urlParams = new URLSearchParams(window.location.search);
    const conversationId = urlParams.get('id'); // '123'
    setConversation(ConversationRepository.getActiveConversation(conversationId));
  }

  if (MessageRepository.getMessageCount() > MESSAGE_COUNT && AuthApi.isTokenExpired()) {
    window.location.href = "/auth/login";
    return (<></>)
  }

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  // Function to toggle sidebar
  const toggleSidebar = () => {
    setIsSidebarActive(!isSidebarActive);
  };


  const onKeyUp = async (e) => {
    if (e.altKey === false && e.keyCode === 13 && e.key === "Enter") {
      setIsInputDisabled(true);
      const prompt = e.target.value;
      e.target.value = "";

      const message = new Message({
        content: prompt,
        sender: MessageSender.ME
      });
      conversation.addMessage(message);
      setConversation(conversation);
      setConversations(ConversationRepository.getConversations());

      let futureMessage = new Message({
        content: null,
        sender: MessageSender.ASSISTANT
      });

      conversation.addMessage(futureMessage);
      setConversation(conversation);
      setConversations(ConversationRepository.getConversations());

      // await AppTools.wait(3000);
      const response = await ChatApi.search(prompt, conversation);
      const jsonData = await response.json();

      conversation.title ??= jsonData.title;
      futureMessage.content = jsonData.message;
      futureMessage.see_more = jsonData.see_more;
      futureMessage.courses = Course.fromList(jsonData.results).slice(0, 4);
      conversation.addMessage(futureMessage);
      conversation = ConversationRepository.save(conversation);
      setConversation(conversation);
      setConversations(ConversationRepository.getConversations());


      // if(res) {
      //   setIsInputDisabled(false);
      // }
      setIsInputDisabled(false);
      console.log(prompt);
    }
  }

  const triggerSearch = async (prompt) => {
    if (MessageRepository.getMessageCount() > MESSAGE_COUNT && AuthApi.isTokenExpired()) {
      window.location.href = "/auth/login";

    } else {

      setIsInputDisabled(true);

      const message = new Message({
        content: prompt,
        sender: MessageSender.ME
      });
      conversation.addMessage(message);
      setConversation(conversation);
      setConversations(ConversationRepository.getConversations());

      let futureMessage = new Message({
        content: null,
        sender: MessageSender.ASSISTANT
      });

      conversation.addMessage(futureMessage);
      setConversation(conversation);
      setConversations(ConversationRepository.getConversations());

      // await AppTools.wait(3000);
      const response = await ChatApi.search(prompt, conversation);
      const jsonData = await response.json();

      conversation.title ??= jsonData.title;
      futureMessage.content = jsonData.message;
      futureMessage.see_more = jsonData.see_more;
      futureMessage.keywords = jsonData.keywords;
      futureMessage.courses = Course.fromList(jsonData.results).slice(0, 4);
      conversation.addMessage(futureMessage);
      conversation = ConversationRepository.save(conversation);
      setConversation(conversation);
      setConversations(ConversationRepository.getConversations());

      setIsInputDisabled(false);
    }

  }

  const onConversationClick = (id) => {
    let conversation = ConversationRepository.getConversation(id);
    conversation.setActive();

    // Assume conversationId is already defined
    const conversationId = conversation.id;
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('id', conversationId);
    window.history.replaceState({}, '', currentUrl.toString());

    setConversation(conversation);
    setConversations(ConversationRepository.getConversations());
    scrollToBottom();
  }

  const onCourseClick = (url) => {
    window.open(url, '_blank');
  }

  const onCategoryClicked = (e, category) => {
    e.preventDefault();
    triggerSearch("I'm looking to learn " + category.name, conversation.getMessages());
    scrollToBottom();
  }

  const onNewConversationClicked = () => {
    Conversation.init()
    Conversation.clearEmptyConversations();
    setConversation(ConversationRepository.getActiveConversation());
  }

  const notHappy = () => {
    let lastMessage = Conversation.getLastMessage(MessageSender.ASSISTANT);
    if (lastMessage) {
      let firstKeyword = lastMessage.keywords.length > 0 ? lastMessage.keywords[0] : undefined;

      if (firstKeyword) {
        triggerSearch("Show me more results about " + firstKeyword, conversation.getMessages());
      }
    }
  }

  const goManual = () => {
    window.location.href = "https://stage.ecourses.directory"; // Replace with your desired URL
  }

  const deleteConversation = (id) => {
    setConversations(ConversationRepository.deleteConversation(id));
    Conversation.init()
    Conversation.clearEmptyConversations();
    setConversation(ConversationRepository.getActiveConversation());
  }

  return (
    <div className="chat-div">
      <div className={`chat-sidebar ${isSidebarActive ? "active" : ""}`}>
        <div className="chat-sidebar-0">
          <button onClick={onNewConversationClicked} className="new-conversation">
            <FontAwesomeIcon icon={faPlus} />
            <span>New chat</span>
          </button>

          <button onClick={toggleSidebar} className="close-panel">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="chat-sidebar-1">
          {conversations ? Object.entries(ConversationRepository.categorizeConversations(conversations))
            .map(([key, value]) => {
              return {
                category: key,
                list: value
              };
            })
            .filter((c) => c.list.length > 0)
            .map(c => <>
              <span className="chat-sidebar-1-span">{c.category}</span>
              {c.list.map(l => <div className="chat-sidebar-field">
                <button data-active={l.isActive === true} onClick={() => onConversationClick(l.id)}>{l.title ?? "Current conversation"}</button>
                <button onClick={() => deleteConversation(l.id)} className="delete-conversation">
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>)}
            </>
            ) : <></>}
        </div>
        <div >
          <button onClick={goManual} className="manual-search">
            Go back to site
          </button>
        </div>
      </div>

      <div className="chat-main">
        <div className="app-bar">
          <a href="#" onClick={toggleSidebar}>
            <img src={menu} alt="Menu" className="chat-menu-div" />
          </a>
        </div>

        <div className="chat-con">

          <div className="chat-listing">
            {conversation && conversation.getMessages().length > 0 ? conversation.getMessages().map(m => m.sender === MessageSender.ME ?
              <div className="your-prompt">
                <div>
                  <p>{m.content}</p>
                </div>
              </div> :
              <div className="search-prompt" data-gradient={m && m.courses.length === 0}>
                <p className="search-prompt-heading">
                  {m.content ?? "Loading"}
                </p>

                <div className="course-listing">
                  {m && m.courses ? m.courses
                    .map((c) =>
                      <div onClick={() => onCourseClick(c.url)} className="card">
                        <img
                          src={c.featured_image}
                          alt=""
                          className="card-image"
                        />
                        <span className="card-category">{c.getCategoryNames()}</span>
                        <p className="card-title" dangerouslySetInnerHTML={{ __html: c.title }} />

                        <ul className="card-ul">
                          <li className="card-li">
                            {" "}
                            <img src={udmey} alt="" className="card-li-img" />
                            <p className="card-li-text">{c.provider}</p>
                          </li>
                          {c.course_session.trim().length > 0 ? <li className="card-li">
                            <img src={demand} alt="" className="card-li-img" />
                            <p className="card-li-text">{c.course_session}</p>
                          </li> : <></>}

                          <li className="card-li">
                            <img src={price} alt="" className="card-li-img" />
                            <p className="card-li-text">{c.course_type}</p>
                          </li>
                          {c.duration.trim().length > 0 ? <li className="card-li">
                            {" "}
                            <img src={time} alt="" className="card-li-img" />
                            <p className="card-li-text">{c.duration}</p>
                          </li> : <></>}

                        </ul>
                        <a href={c.url}>
                          <div className="card-button-div">
                            <p className="card-button-text">Read More</p>
                            <div className="card-button-img-div">
                              <img
                                src={arrowRight}
                                alt=""
                                className="card-button-img"
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                    ) : <></>}
                </div>

                {m.getCategories().length > 0 ? <>
                  <div className="course-categories">
                    <h4>Related categories</h4>
                    <div className="list">
                      {m.getCategories().length > 0 ? m.getCategories().map((course, index) => (
                        <a key={index} rel="norefferer" onClick={(e) => onCategoryClicked(e, course)} className="category" href={course.url} dangerouslySetInnerHTML={{ __html: course.name }}></a>
                      )) : <></>}
                    </div>
                  </div>
                  <div className="quick-actions">
                    {m.courses.length > 0 ? <button className="notHappy" onClick={notHappy}>Suggest me other results</button> : <></>}
                    {m.courses.length > 0 ? <a className="seeMore" rel="norefferer" target="_blank" href={m.see_more}><span>See more</span><FontAwesomeIcon color="#ffffff" icon={faArrowUpRightFromSquare} /></a> : <></>}
                  </div>

                </> : <></>}


              </div>

            ) : <div class="container">
              <div class="welcome-section">
                <h1>Welcome to CourseGPT!</h1>
                <p>
                  We're thrilled to have you on board. CourseGPT is designed to help you learn and grow with personalized
                  course recommendations and a rich learning experience. Explore the features and start your journey to
                  mastering new skills!
                </p>
                <a href="#how-to-use" class="cta-button">Learn How to Use</a>
              </div>

              <div class="prompt-examples" id="prompt-examples">
                <h2>Try These Example Prompts</h2>
                <p>Get started quickly by using these example prompts to see how CourseGPT can assist you:</p>
                {promptExamples.map(p => <button onClick={() => triggerSearch(p.text)} class="prompt-button">
                  <span>Example {p.id}: {p.text}</span>
                </button>)}
              </div>
            </div>}
          </div>

          <div ref={messagesEndRef} />
        </div>

        <div className="chat-input-con">
          <input
            type="text"
            placeholder="What do you want to learn today?"
            className="chat-input"
            onKeyUp={onKeyUp}
            disabled={isInputDisabled}
          />

          <img src={arrowRight} alt="" className="chat-input-img" />
        </div>
      </div>
    </div>
  );
};

export default Chat;
