
export default class Course {
    constructor(object) {
        this.id = object.id;
        this.title = object.title;
        this.url = object.url;
        this.featured_image = object.featured_image;
        this.course_type = object.course_type;
        this.duration = object.duration;
        this.course_session = object.course_session;
        this.level = object.level;
        this.language = object.language;
        this.taught_by = object.taught_by;
        this.categories = object.categories;
        this.provider = object.provider;
    }

    getCategoryNames() {
       //return Object.values(this.categories).join(', ');
       const categoryNames = Object.values(this.categories);

       // Get the last entry
       const lastEntry = categoryNames[categoryNames.length - 1];
       return lastEntry;
    }

    /**
   *
   * @param {Array} objectList
   * @returns {Course[]}
   */
    static fromList(objectList = []) {
        if (objectList.length === 0)
            return objectList;
        return objectList.map(o => new Course(o));
    }

}