import React, { Component } from 'react';
import { Snackbar, Alert } from '@mui/material';

class SuccessSnackbar extends Component {
    handleClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        const { open } = this.props;

        return (
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={this.handleClose}
                action={
                    <button onClick={this.handleClose}>Close</button>
                }
            >
                <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
                    Login successful
                </Alert>
            </Snackbar>
        );
    }
}

export default SuccessSnackbar;
