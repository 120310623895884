import Course from "./Course";

export class MessageSender {
    static ME = "user";
    static ASSISTANT = "assistant";
}

export default class Message {
    constructor(object) {
        this.id = object.id;
        this.conversationId = object.conversationId;
        this.keywords = object.keywords ?? [];
        this.sender = object.sender;
        this.content = object.content;
        this.see_more = object.see_more;
        this.courses = Course.fromList(object.courses);
    }

    getCategories() {
        const categoriesSet = new Set(); // Use a Set to keep unique categories

        this.courses.forEach(course => {
            Object.entries(course.categories).forEach(([url, category]) => {
                // Create a unique identifier for each category
                const uniqueCategory = `${url}|${category}`;
                // Add to the Set (duplicates will be automatically ignored)
                categoriesSet.add(uniqueCategory);
            });
        })


        // Convert the Set back to an array of objects
        const categories = Array.from(categoriesSet).map(categoryString => {
            const [url, category] = categoryString.split('|');
            return { url: url, name: category };
        });

        return categories;
    }

    /**
    *
    * @param {Array} objectList
    * @returns {Message[]}
    */
    static fromList(objectList = []) {
        if (objectList.length === 0)
            return objectList;
        return objectList.map(o => new Message(o));
    }

}