import React from "react";
import "../assets/login-and-signup.css";
import AuthApi from "../api/AuthApi";
import SuccessSnackbar from "./SuccessSnackBar";
import ErrorSnackbar from "./ErrorSnackBar";


export default class LoginForm extends React.Component {
    static LOGIN = 1;
    static SIGNUP = 2;

    constructor(props) {
        super(props);

        this.state = {
            selectedForm: props.selectedForm ?? LoginForm.LOGIN,
            successOpen: false,
            errorOpen: false,
            errorMessage: ''
        }
    }

    toggleForm() {
        this.setState({
            searchQuery: this.state.selectedForm === LoginForm.LOGIN ? LoginForm.SIGNUP : LoginForm.LOGIN
        });
    }

    handleSuccessClose = () => {
        this.setState({ successOpen: false });
    };

    handleErrorClose = () => {
        this.setState({ errorOpen: false });
    };

    wrapOverlay(html) {
        return <>
            <SuccessSnackbar
                open={this.state.successOpen}
                onClose={this.handleSuccessClose}
            />

            <ErrorSnackbar
                open={this.state.errorOpen}
                onClose={this.handleErrorClose}
                errorMessage={this.state.errorMessage}
            />

            <div className="auth-container">
                {html}
            </div>
        </>
    }


    renderSignup() {
        return this.wrapOverlay(
            <div className="form-container signup-container">
                <form onSubmit={this.handleSignupSubmit}>
                    <h2 className="title">Sign Up</h2>
                    <div className="input-field">
                        <input
                            type="text"
                            name="username"
                            required
                            onChange={this.handleInputChange}
                        />
                        <label>Username</label>
                        <span className="focus-border"></span>
                    </div>
                    <div className="input-field">
                        <input
                            type="email"
                            name="email"
                            required
                            onChange={this.handleInputChange}
                        />
                        <label>Email</label>
                        <span className="focus-border"></span>
                    </div>
                    <div className="input-field">
                        <input
                            type="password"
                            name="password"
                            required
                            onChange={this.handleInputChange}
                        />
                        <label>Password</label>
                        <span className="focus-border"></span>
                    </div>
                    <button type="submit" className="btn">Sign Up</button>
                    <p className="text">
                        Already have an account? <a href="/auth/login" id="signIn">Login</a>
                    </p>
                </form>
            </div>
        );
    }


    // Handle signup form submission
    handleSignupSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        const { username, email, password } = this.state;

        // Add your signup logic here, such as calling an API
        // Example:
        AuthApi.register(username, email, password)
            .then(response => {
                AuthApi.login(email, password)
                    .then(response => {
                        this.setState({ successOpen: true });

                        setTimeout(() => {
                            window.location.href = "/";
                        }, 3000);
                    })
                    .catch(error => {
                        this.setState({
                            errorMessage: error.message,
                            errorOpen: true
                        });
                    });
            })
            .catch(error => {
                // Handle error
            });
    }

    // Handle form input changes
    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    renderLogin() {
        return this.wrapOverlay(
            <div className="form-container login-container">
                <form onSubmit={this.handleSubmit}>
                    <h4 className="sub-title">You must login to continue chatting</h4>
                    <h2 className="title">Login</h2>
                    <div className="input-field">
                        <input
                            type="email"
                            name="email"
                            required
                            onChange={this.handleInputChange}
                        />
                        <label>Email</label>
                        <span className="focus-border"></span>
                    </div>
                    <div className="input-field">
                        <input
                            type="password"
                            name="password"
                            required
                            onChange={this.handleInputChange}
                        />
                        <label>Password</label>
                        <span className="focus-border"></span>
                    </div>
                    <button type="submit" className="btn">Login</button>
                    <p className="text">
                        Don't have an account? <a href="/auth/signup" id="signUp">Sign Up</a>
                    </p>
                </form>
            </div>
        );
    }

    // Handle form submission
    handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        const { email, password } = this.state;

        // Add your login logic here, such as calling an API
        // Example:
        AuthApi.login(email, password)
            .then(response => {
                this.setState({ successOpen: true });

                setTimeout(() => {
                    window.location.href = "/";
                }, 3000);
            })
            .catch(error => {
                this.setState({
                    errorMessage: error.message,
                    errorOpen: true
                });
            });
    }

    render() {
        if (this.state.selectedForm === LoginForm.LOGIN) {
            return this.renderLogin();
        } else {
            return this.renderSignup();
        }
    }

}