import Conversation from "../model/Conversation";


export default class ConversationRepository {
    static TABLE_NAME = "conversation";

    /**
     * 
     * @returns {number}
     */
    static nextId() {
        let lastId = ConversationRepository.lastId();
        lastId++;
        localStorage.setItem('last-c-id', lastId);
        return lastId;
    }

    /**
     * 
     * @returns {number}
     */
    static lastId() {
        let lastId = localStorage.getItem('last-c-id') ?? 0;
        return parseInt(lastId);
    }

    /**
     * 
     * @returns {Conversation[]}
     */
    static getConversations() {
        const jsonConversations = JSON.parse(localStorage.getItem(this.TABLE_NAME));
        const conversations = Conversation.fromList(jsonConversations ?? []);
        if(conversations.length === 0) {


        }
        // Sort conversations by date
        conversations.sort((a, b) => new Date(b.date) - new Date(a.date));
        return conversations;
    }

    static deleteConversation(id) {
        let conversations = this.getConversations();
        conversations = conversations.filter((c) => c.id !== id);
        this.saveAll(conversations);
        return conversations;
    }

    /**
    * 
    * @returns {Conversation}
    */
    static getActiveConversation(conversationId = null) {
        let conversation = null;

        if (conversationId) {
            conversation = this.getConversation(parseInt(conversationId));
            conversation.setActive();
        } else {
            const conversations = ConversationRepository.getConversations();

            if (conversations.some(c => c.isActive === true)) {
                return conversations.find(c => c.isActive === true);
            }
            conversation = new Conversation({
                isActive: true,
                title: null,
            });
        }
        return this.save(conversation);
    }

    static getConversation(conversationId) {
        const conversations = ConversationRepository.getConversations();
        return conversations.find(conversation => conversation.id === conversationId) ?? null;
    }

    /**
     * 
     * @param {Conversation} conversation 
     * @return {Conversation}
     */
    static save(conversation) {
        let conversations = ConversationRepository.getConversations();
        if (conversation.id == null) {
            conversation.id = ConversationRepository.nextId();
            conversations.push(conversation);
        } else {
            conversations = conversations.map(c => {
                if (c.id === conversation.id) {
                    return conversation;
                } else {
                    return c;
                }
            })
        }
        localStorage.setItem(this.TABLE_NAME, JSON.stringify(conversations));
        return conversations.find(c => c.id === conversation.id);
    }

    static saveAll(conversations) {
        localStorage.setItem(this.TABLE_NAME, JSON.stringify(conversations));
    }

    static getStartOfDay(date) {
        const start = new Date(date);
        start.setUTCHours(0, 0, 0, 0);
        return start;
    }

    /**
 * 
 * @param {Conversation[]} conversations 
 * @returns 
 */
    static categorizeConversations(conversations) {
        const today = this.getStartOfDay(new Date());
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const previous7Days = new Date(today);
        previous7Days.setDate(today.getDate() - 7);
        const previous30Days = new Date(today);
        previous30Days.setDate(today.getDate() - 30);

        const categories = {
            Today: [],
            Yesterday: [],
            'Previous 7 days': [],
            'Previous 30 days': [],
        };

        conversations.forEach(conversation => {
            const conversationDate = new Date(conversation.date);
            const conversationStartOfDay = this.getStartOfDay(conversationDate);

            if (this.isSameDay(conversationStartOfDay, today)) {
                categories.Today.push(conversation);
            } else if (this.isSameDay(conversationStartOfDay, yesterday)) {
                categories.Yesterday.push(conversation);
            } else if (conversationStartOfDay >= previous7Days) {
                categories['Previous 7 days'].push(conversation);
            } else if (conversationStartOfDay >= previous30Days) {
                categories['Previous 30 days'].push(conversation);
            } else {
                const month = conversationDate.toLocaleString('default', { month: 'long' });
                const year = conversationDate.getFullYear();
                const monthYearKey = `${month} ${year}`;

                if (!categories[monthYearKey]) {
                    categories[monthYearKey] = [];
                }

                categories[monthYearKey].push(conversation);
            }
        });

        return categories;
    }


    /**
 * 
 * @param {Date} date1 
 * @param {Date} date2 
 * @returns {boolean}
 */
    static isSameDay(date1, date2) {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    }

}